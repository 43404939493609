// src/components/IconDemo.tsx
import React, { useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import {
  Box,
  Grid,
  RadioGroup,
  Radio,
  Input,
  Select,
  Option,
  Typography,
  Button,
  Alert,
  Tooltip,
  Stack,
} from '@mui/joy';
import { getIcons } from './iconLoader';
import { Icon } from './types';
import useClipboard from 'react-use-clipboard';
import { useDebouncedCallback } from 'use-debounce';

type IconButtonProps = {
  icon: Icon;
  onCopy: () => void;
};

const IconButton: React.FC<IconButtonProps> = ({ icon, onCopy }) => {
  const path = `import ${icon.name} from '${icon.path}';`;

  const [, copy] = useClipboard(path);

  const copyIcon = (icon: Icon) => {
    copy();
    onCopy();
  };

  return (
    <Button onClick={() => copyIcon(icon)} variant="plain">
      <img src={icon.iconPath} alt={icon.name} />
      <Typography>{icon.name}</Typography>
    </Button>
  );
};

const PAGE_SIZE = 40;

const IconDemo: React.FC = () => {
  const [view, setView] = useState<'Line' | 'Solid'>('Line');
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>('All');
  const [isCopied, setCopied] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const icons = getIcons(view);
  const [filteredResults, setFilteredResults] = useState<Icon[]>(icons);

  const fuse = useMemo(() => {
    return new Fuse(icons, {
      keys: ['name'],
      threshold: 0.2,
    });
  }, [icons]);

  const handleSearch = useDebouncedCallback((value: string) => {
    let results = icons;
    if (value.trim() !== '') {
      results = fuse.search(value).map((result) => result.item);
    }
    if (category !== 'All') {
      results = results.filter((icon) => icon.category === category);
    }
    setFilteredResults(results);
    setCurrentPage(1);
  }, 300);

  const handleCategoryChange = (value: string) => {
    setCategory(value);
    let results = icons;
    if (search.trim() !== '') {
      results = fuse.search(search).map((result) => result.item);
    }
    if (value !== 'All') {
      results = results.filter((icon) => icon.category === value);
    }
    setFilteredResults(results);
    setCurrentPage(1);
  };

  const categories = useMemo(() => ['All', ...Array.from(new Set(icons.map((icon) => icon.category)))], [icons]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleClearSearch = () => {
    setSearch('');
    handleSearch('');
    setCurrentPage(1);
  };

  useEffect(() => {
    handleSearch(search);
  }, [view, category, search, handleSearch]);

  const paginatedResults = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return filteredResults.slice(startIndex, startIndex + PAGE_SIZE);
  }, [filteredResults, currentPage]);

  const totalPages = Math.ceil(filteredResults.length / PAGE_SIZE);

  return (
    <Box padding="8px">
      <RadioGroup name="view" value={view} onChange={(e) => setView(e.target.value as 'Line' | 'Solid')}>
        <Radio value="Line" label="Line" />
        <Radio value="Solid" label="Solid" />
      </RadioGroup>

      <Input
        placeholder="Search for an icon"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          handleSearch(e.target.value);
        }}
        endDecorator={
          <Tooltip title="Clear search">
            <Button onClick={handleClearSearch}>X</Button>
          </Tooltip>
        }
      />

      <Typography>Click an icon to copy its path</Typography>

      <Select value={category} onChange={(_event, value) => handleCategoryChange(value ?? 'All')}>
        {categories.map((category) => (
          <Option value={category} key={category}>
            {category}
          </Option>
        ))}
      </Select>

      {isCopied && (
        <Box position="fixed" bottom={0} right={0} zIndex={1000} padding="8px" display="flex">
          <Alert color="success" endDecorator={<Button onClick={() => setCopied(false)}>Close</Button>}>
            Copied to clipboard!
          </Alert>
        </Box>
      )}
      <Grid container spacing={2}>
        {paginatedResults.map((icon) => (
          <Grid xs={3} key={icon.name}>
            <IconButton icon={icon} onCopy={handleCopy} />
          </Grid>
        ))}
      </Grid>

      <Stack direction="row" justifyContent="center" marginTop="16px">
        <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
          Previous
        </Button>
        <Typography marginX="16px">
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default IconDemo;
