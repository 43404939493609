import { Icon } from './types';

// src/components/iconLoader.ts
const importAll = (r: __WebpackModuleApi.RequireContext) =>
  r.keys().map((key) => ({ path: r(key), originalPath: key }));

const lineIcons = importAll(require.context('../../src/Line', true, /\.svg$/));
const solidIcons = importAll(require.context('../../src/Solid', true, /\.svg$/));

const ICON_PATH_TEMPLATE = '@audioeye/ui-icons/ICON_TYPE/CATEGORY/NAME';

const formatIcons = (icons: { path: string; originalPath: string }[], iconType: 'Line' | 'Solid'): Icon[] =>
  icons.map((icon) => {
    const parts = icon.originalPath.split('/');
    const category = parts[parts.length - 2];
    // need to update name from something like 'bell-01' to 'Bell01' or 'notification-bell' to 'NotificationBell'
    const name = parts[parts.length - 1]
      .replace('.svg', '')
      .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
      .replace(/^./, (str) => str.toUpperCase())
      .replace(/-/g, '');
    // need to generate the real path
    // Our imports use shortened versions of the category names
    const path = ICON_PATH_TEMPLATE.replace('ICON_TYPE', iconType)
      .replace('CATEGORY', category.split(' ')[0])
      .replace('NAME', name); 
    return { path, name, category, iconPath: icon.path };
  });

// now we need to remove duplicate icons that share the same name
// we will keep the first one we find
const removeDuplicates = (icons: Icon[]): Icon[] => {
  const seen = new Set();
  return icons.filter((icon) => {
    if (seen.has(icon.name)) {
      return false;
    }
    seen.add(icon.name);
    return true;
  });
};

export const getIcons = (view: 'Line' | 'Solid') => {
  if (view === 'Line') {
    return removeDuplicates(formatIcons(lineIcons, 'Line'));
  } else {
    return removeDuplicates(formatIcons(solidIcons, 'Solid'));
  }
};
